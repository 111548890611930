import React, { FC, useContext, useEffect, useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import LocationMarker from 'components/AnyReactComponent/LocationMarker';
import CommentListing from 'components/CommentListing/CommentListing';
import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import { DateRage } from 'components/HeroSearchForm/StaySearchForm';
import StartRating from 'components/StartRating/StartRating';
import GoogleMapReact from 'google-map-react';
import useWindowSize from 'hooks/useWindowResize';
import moment, { Moment } from 'moment';
import { useParams } from 'react-router-dom'
import { DayPickerRangeController, FocusedInputShape, isInclusivelyAfterDay } from 'react-dates';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import NcImage from 'shared/NcImage/NcImage';
import LikeSaveBtns from './LikeSaveBtns';
import ModalPhotos from './ModalPhotos';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import carUtilities1 from 'images/carUtilities/1.png';
import carUtilities2 from 'images/carUtilities/2.png';
import carUtilities3 from 'images/carUtilities/3.png';
import carUtilities4 from 'images/carUtilities/4.png';
import carUtilities5 from 'images/carUtilities/5.png';
import carUtilities6 from 'images/carUtilities/6.png';
import carUtilities7 from 'images/carUtilities/7.png';
import carUtilities8 from 'images/carUtilities/8.png';
import RentalCarDatesRangeInput from 'components/HeroSearchForm/RentalCarDatesRangeInput';
import { TimeRage } from 'components/HeroSearchForm/RentalCarSearchForm';
import MobileFooterSticky from './MobileFooterSticky';
import axios from '../../services/api';
import { Listbox } from "@headlessui/react";
import { useHistory } from 'react-router-dom'
import { CheckIcon } from "@heroicons/react/24/solid";
import { message, Modal, Switch } from 'antd';
import { useDateContext } from 'contexts/DateContext';
import { BookingContext, useBookingData } from 'contexts/BookingContext';
import { UserContext } from 'contexts/UserContext';
export interface ListingCarDetailPageProps {
    className?: string;
}

// const PHOTOS: string[] = [
//     '','','',''
// ];

// const includes_demo = [
//     { name: '...' },
//     { name: '...' },
//     { name: 'Theft Protection with $19,999 excess' },
//     { name: 'Unlimited mileage' },
//     {
//         name: 'Car interiors and exteriors cleaned with disinfectant before pick-up',
//     },
//     { name: 'Masks are required at the pick-up location' },
// ];

// const Amenities_demos = [
//     { name: '59 MPG Combined, 58 City/60 Hwy', icon: carUtilities1 },
//     {
//         name: 'Forward Collision-Avoidance Assist with Pedestrian Detection (FCA-Ped)',
//         icon: carUtilities2,
//     },
//     { name: '139-hp gas/electric combined', icon: carUtilities3 },
//     { name: 'Proximity Key with push button start', icon: carUtilities4 },
//     { name: '8-inch color touchscreen display audio', icon: carUtilities5 },
//     { name: 'Smart Cruise Control with Stop & Go (SCC)', icon: carUtilities6 },
//     { name: 'LED Daytime Running Lights (DRL)', icon: carUtilities7 },
//     { name: 'Blind-Spot Collision Warning (BCW)', icon: carUtilities8 },
// ];

// type aminities = {
//     icon: any;
//     name: string;
// };

// type VehicleData = {
//     vehicleName: string;
//     images: string[];
//     veichleLocation: string;
//     aminities: aminities[];
// };

const ListingCarDetailPage: FC<ListingCarDetailPageProps> = ({ className = '' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    let { id }: any = useParams();
    const history = useHistory();
    const user: any = useContext(UserContext);
    const [open, setOpen] = useState(false);
    // USE STATE
    var { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue }: any = useDateContext();
    const { booking, setBooking }: any = useBookingData();

    const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] = useState<FocusedInputShape>('startDate');

    const windowSize = useWindowSize();

    const getDaySize = () => {
        if (windowSize.width <= 375) {
            return 34;
        }
        if (windowSize.width <= 500) {
            return undefined;
        }
        if (windowSize.width <= 1280) {
            return 56;
        }
        return 48;
    };

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);

    const [vehicleData, setVehicleData] = useState<any>({
        vehicleName: '...',
        images: [],
        included: [],
        price: 0,
        locationCoordinates: "",
        vehicleLocation: 'Ameerpet, Hyderabad',
        amenities: [
            // { name: 'Mileage 50 Kmpl', icon: carUtilities1 },
            // {
            //     name: 'Forward Collision-Avoidance Assist with Pedestrian Detection (FCA-Ped)',
            //     icon: carUtilities2,
            // },
        ],
    });

    const getDate = (startDate: Moment, startTime: string) => {
        // if(startDate==null) return;

        var time = startTime.split(" ");
        var hour = time[0].split(":")[0];
        var min = time[0].split(":")[1];
        var ampm = time[1];
        if (ampm == "PM" && parseInt(hour) != 12) {
            hour = (parseInt(hour) + 12).toString();
        }
        var date = startDate?.toDate().setHours(parseInt(hour), parseInt(min), 0, 0);
        return date;
    }


    const [rentDuration, setRentDuration] = useState(0);

    const [rentPrice, setRentPrice] = useState(0);

    const [addonsPrice, setaddonsPrice] = useState(0);

    const calculateRent = () => {
        if (dateRangeValue.startDate == null || dateRangeValue.endDate == null) return;
        console.log(getDate(dateRangeValue.startDate, timeRangeValue.startTime));

        var hours = Math.abs(getDate(dateRangeValue.startDate, timeRangeValue.startTime) - getDate(dateRangeValue.endDate, timeRangeValue.endTime)) / 36e5;
        if (hours < vehicleData.minDuration) {
            message.warning({ content: "Minimum rent duration is " + vehicleData.minDuration + " hours", key: "minDuration" });
            hours = vehicleData.minDuration;
        }
        setRentDuration(hours);
        setRentPrice(
            (Math.ceil(hours / 24)) * ((booking.outStation ? vehicleData.outStationPrice : vehicleData.price) * 24)

        );
        setaddonsPrice(0);
    }

    useEffect(() => {
        calculateRent();
    }, [dateRangeValue, timeRangeValue, vehicleData, booking.outStation]);

    const getVehicleData = async () => {

        axios.get('/vehicle/' + id).then((response => {

            const rawData = response.data;
            console.log(response.data);

            setVehicleData({
                id: rawData.data._id,
                vehicleName: rawData.data.name,
                type: rawData.data.type,
                gears: rawData.data.gears,
                description: rawData.data.description,
                fuelCapacity: rawData.data.fuelCapacity,
                included: rawData.data.included,
                fuel: rawData.data.fuel,
                price: rawData.data.price,
                outStationPrice: rawData.data.outStationPrice,
                KMLimitPerHourOutStation: rawData.data.KMLimitPerHourOutStation,
                KMLimitPerHour: rawData.data.KMLimitPerHour,
                extraKMCharges: rawData.data.extraKMCharges,
                locationCoordinates: rawData.data.locationCoordinates,
                images: [rawData.data.image, ...rawData.data.images],
                location: rawData.data.location,
                amenities: rawData.data.features,
                minDuration: rawData.data.minDuration,
            });
            // if no outStationPrice or outStationPrice is 0, then setBooking.outStation to false
            if ((rawData.data.outStationPrice == 0) || (rawData.data.outStationPrice == null) || (rawData.data.outStationPrice == undefined)) {
                setBooking({ ...booking, outStation: false });
            }
        })).catch((error) => {
            history.push('/404');
            console.log(error);
        });

    };
    useEffect(() => {
        getVehicleData();
        calculateRent();
    }, []);

    const renderSection1 = () => {
        return (
            <div className="listingSection__wrap !space-y-4 pb-4 sm:hidden">
                {/* 1 */}
                <div className="flex justify-between items-center">
                    {/* <Badge color="pink" name="BMW car" /> */}
                    <div></div>
                    <LikeSaveBtns />
                </div>

                {/* 2 */}
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">{vehicleData.vehicleName}</h2>

                {/* 3 */}
                <div className="flex items-center">
                    {/* <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" /> */}
                    <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
                        Category:
                        {/* Verified */}
                        <span className="text-neutral-900 dark:text-neutral-200 font-medium capitalize"> {vehicleData.type}</span>
                    </span>
                </div>
                {/* 4 */}
                <div className="flex items-center space-x-4">
                    {/* <StartRating />
                    <span>·</span> */}
                    <a href="https://www.google.com/maps/search/?api=1&query=17.324760809168968,78.52926361462093">
                        <i className="las la-map-marker-alt"></i>
                        <span className="ml-1"> {vehicleData.location}</span>
                    </a>
                </div>


                {/* 5 */}
                <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

                {/* 6 */}
                <div className="flex items-center justify-between xl:justify-start space-x-4 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="las la-cogs text-2xl"></i>
                        <span className=""> {vehicleData.gears} gears</span>
                    </div>
                    {/* <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="las la-motorcycle text-2xl"></i>
                        <span className=""> 1 helmet</span>
                    </div> */}
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="las la-charging-station text-2xl"></i>
                        <span className="">Fuel {vehicleData.fuel}</span>
                    </div>

                </div>
            </div>
        );
    };

    const renderSection1_mod = () => {
        return (
            <div className="listingSection__wrap !space-y-4 hidden sm:block">
                {/* 1 */}
                <div className="flex justify-between items-center">
                    {/* <Badge color="pink" name="BMW car" /> */}
                    <div></div>
                    <LikeSaveBtns />
                </div>

                {/* 2 */}
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">{vehicleData.vehicleName}</h2>

                {/* 3 */}
                <div className="flex items-center">
                    {/* <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" /> */}
                    <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
                        Category:
                        {/* Verified */}
                        <span className="text-neutral-900 dark:text-neutral-200 font-medium capitalize"> {vehicleData.type}</span>
                    </span>
                </div>
                {/* 4 */}
                <div className="flex items-center space-x-4">
                    {/* <StartRating />
                    <span>·</span> */}
                    {/* <span>
                        <i className="las la-map-marker-alt"></i>
                        <span className="ml-1"> {vehicleData.location}</span>
                    </span> */}
                    <a href="https://www.google.com/maps/search/?api=1&query=17.324760809168968,78.52926361462093">
                        <i className="las la-map-marker-alt"></i>
                        <span className="ml-1"> {vehicleData.location}</span>
                    </a>
                </div>


                {/* 5 */}
                <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

                {/* 6 */}
                <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="las la-cogs text-2xl"></i>
                        <span className=""> {vehicleData.gears} gears</span>
                    </div>
                    {/* <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="las la-motorcycle text-2xl"></i>
                        <span className=""> 1 helmet</span>
                    </div> */}
                    <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        <i className="las la-charging-station text-2xl"></i>
                        <span className="">Fuel {vehicleData.fuel}</span>
                    </div>
                    {
                        // book now button to scroll to #booknow_container
                        // <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                        //     <ButtonPrimary
                        //         type="button"
                        //         onClick={() => {
                        //             document.getElementById('booknow_container')?.scrollIntoView();
                        //         }}
                        //     >
                        //         Book Now
                        //     </ButtonPrimary>
                        // </div>

                    }
                </div>
            </div>
        );
    };

    //
    const renderSectionTienIch = () => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">Vehicle parameters & utilities </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">Questions are at the heart of making things great.</span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* 6 */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
                    {/* TIEN ICH 1 */}
                    {vehicleData.amenities.filter((e: string) => e != "").map((item: any, index: number) => (
                        <div key={index} className="flex items-center space-x-4 ">
                            {/* <div className="w-10 flex-shrink-0">
                                <img src={item.icon} alt="" />
                            </div> */}
                            <i className="las la-check-circle text-2xl"></i>

                            <span>{item}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSection2 = () => {
        return (
            <div className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">Vehicle descriptions</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="text-neutral-6000 dark:text-neutral-300">
                    <p>
                        {vehicleData.description}
                    </p>
                </div>
            </div>
        );
    };

    const renderSection3 = () => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">Include </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">Included in the price</span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* 6 */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                    {
                        // includes_demo
                        //     .filter((_, i) => i < 12)
                        vehicleData?.included?.filter((e: string) => e != "")
                            ?.map((item: any) => (
                                <div key={item} className="flex items-center space-x-3">
                                    <i className="las la-check-circle text-2xl"></i>
                                    <span>{item}</span>
                                </div>
                            ))}
                </div>
            </div>
        );
    };

    const renderSectionCheckIndate = () => {
        return (
            <div className="listingSection__wrap lg:hidden">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">Availability</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">Prices may increase on weekends or holidays</span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* CONTENT */}
                <div className="listingSection__wrap__DayPickerRangeController flow-root">
                    <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
                        <DayPickerRangeController
                            minimumNights={0}
                            startDate={dateRangeValue.startDate}
                            endDate={dateRangeValue.endDate}
                            onDatesChange={(date) => setDateRangeValue(date)}
                            focusedInput={focusedInputSectionCheckDate}
                            onFocusChange={(focusedInput) => setFocusedInputSectionCheckDate(focusedInput || 'startDate')}
                            initialVisibleMonth={null}
                            numberOfMonths={windowSize.width < 1280 ? 1 : 2}
                            daySize={getDaySize()}
                            hideKeyboardShortcutsPanel
                            isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
                        />
                    </div>
                </div>

                {/*  */}
                <div className="flex space-x-8">
                    <div className="w-1/2 space-y-2">
                        <label className="font-medium" htmlFor="startTime">
                            Pick up time:
                        </label>
                        <TimeSelect value={timeRangeValue.startTime} onChange={
                            (time: string) => setTimeRangeValue({ ...timeRangeValue, startTime: time })
                        } />
                        {/* <Input defaultValue={timeRangeValue.startTime} value={timeRangeValue.startTime} rounded="rounded-xl" id="startTime" type="time" step='3600' onChange={
                            (e) => {
                                // set minutes to 0
                                console.log(e.target.value);
                                
                                const time = e.target.value.split(':');
                                time[1] = '00';
                                setTimeRangeValue({ ...timeRangeValue, startTime: time.join(':') });
                            }
                        } /> */}
                    </div>
                    <div className="w-1/2 space-y-2">
                        <label className="font-medium" htmlFor="endTime">
                            Drop off time:
                        </label>
                        {/* <Input defaultValue={timeRangeValue.endTime} rounded="rounded-xl" id="endTime" type="time" onChange={(e) => console.log(e)} /> */}
                        <TimeSelect value={timeRangeValue.endTime} onChange={
                            (time: string) => setTimeRangeValue({ ...timeRangeValue, endTime: time })
                        } />
                    </div>
                </div>
            </div>
        );
    };

    const renderSection5 = () => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold">Car Owner</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* host */}
                <div className="flex items-center space-x-4">
                    <Avatar hasChecked hasCheckedClass="w-4 h-4 -top-0.5 right-0.5" sizeClass="h-14 w-14" radius="rounded-full" />
                    <div>
                        <a className="block text-xl font-medium" href="##">
                            Kevin Francis
                        </a>
                        <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
                            {/* <StartRating />
                            <span className="mx-2">·</span> */}
                            <span> 12 places</span>
                        </div>
                    </div>
                </div>

                {/* desc */}
                <span className="block text-neutral-6000 dark:text-neutral-300">
                    Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides accommodation, an outdoor swimming pool, a bar, a shared lounge, a garden and
                    barbecue facilities...
                </span>

                {/* info */}
                <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
                    <div className="flex items-center space-x-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                        </svg>
                        <span>Joined in March 2016</span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                            />
                        </svg>
                        <span>Response rate - 100%</span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <span>Fast response - within a few hours</span>
                    </div>
                </div>

                {/* == */}
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div>
                    <ButtonSecondary href="##">See host profile</ButtonSecondary>
                </div>
            </div>
        );
    };

    const renderSection6 = () => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* Content */}
                <div className="space-y-5">
                    <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
                    <div className="relative">
                        <Input fontClass="" sizeClass="h-16 px-4 py-3" rounded="rounded-3xl" placeholder="Share your thoughts ..." />
                        <ButtonCircle className="absolute right-2 top-1/2 transform -translate-y-1/2" size=" w-12 h-12 ">
                            <ArrowRightIcon className="w-5 h-5" />
                        </ButtonCircle>
                    </div>
                </div>

                {/* comment */}
                <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
                    <CommentListing className="py-8" />
                    <CommentListing className="py-8" />
                    <CommentListing className="py-8" />
                    <CommentListing className="py-8" />
                    <div className="pt-8">
                        <ButtonSecondary>View more 20 reviews</ButtonSecondary>
                    </div>
                </div>
            </div>
        );
    };

    const renderSection7 = () => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">Location</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">{vehicleData.location}</span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

                {/* MAP */}
                <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                    <div className="rounded-xl overflow-hidden">

                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3808.809030877582!2d78.52926361462093!3d17.324760809168968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba3f4cbb9a09d%3A0x70f933ad7d4b918c!2sStart%20Rides!5e0!3m2!1sen!2sin!4v1668775244159!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} loading="lazy" ></iframe>
                        {/* <GoogleMapReact
                            bootstrapURLKeys={{
                                key: 'AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY',
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            defaultZoom={15}
                            defaultCenter={{
                                lat: 55.9607277,
                                lng: 36.2172614,
                            }}
                        >
                            <LocationMarker lat={55.9607277} lng={36.2172614} />
                        </GoogleMapReact> */}
                    </div>
                </div>
            </div>
        );
    };

    const renderSection8 = () => {
        return (
            <div className="listingSection__wrap lg:!mb-4">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold">Things to know</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

                {/* CONTENT */}
                <div>
                    <h4 className="text-lg font-semibold">Cancellation policy</h4>
                    <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                        Please request Cancellation 24 hrs prior the booking time.
                    </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

                {/* CONTENT */}
                <div>
                    <h4 className="text-lg font-semibold">Special Note</h4>
                    <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                        We StartRides Team are greatful to have you as our privileged customer and we would recommend to drive safe and avoid rash driving.
                    </span>
                </div>
            </div>
        );
    };

    const renderSidebarPrice = () => {
        return (
            <div id="booknow_container" className="listingSectionSidebar__wrap shadow-xl">
                {/* OUTSTATION TOGGLE */}
                <div className={((vehicleData.outStationPrice == undefined || vehicleData.outStationPrice == 0) ? "hidden" : "")}>
                    <div className={" flex justify-between items-center"}>
                        <h2 className="text-2xl font-semibold">Price</h2>
                        <div className="flex items-center space-x-2">
                            <span className="text-sm font-semibold">Outstation</span>
                            <Switch
                                checked={booking.outStation}
                                onChange={() => { setBooking({ ...booking, outStation: !booking.outStation }) }}
                                className={`${booking.outStation ? 'bg-primary-500' : 'bg-neutral-200 dark:bg-neutral-700'}
                                    relative inline-flex items-center rounded-full w-11`}
                            />
                        </div>
                    </div>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

                </div>
                {/* PRICE */}
                <div className="flex justify-between">
                    <div className='flex flex-col'>
                        <span className={"text-3xl font-semibold " + (
                            booking.outStation ? "text-gray-500" : ""
                        )}>
                            ₹ {(vehicleData.price) * 24}
                            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">/ day</span>
                        </span>

                        <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">In Station</span>
                    </div>
                    {/* <StartRating /> */}
                    {
                        (vehicleData.outStationPrice || vehicleData.outStationPrice != 0) &&
                        <div className='flex flex-col items-end'>
                            <div className={"text-3xl font-semibold " + (
                                !booking.outStation ? "text-gray-500" : ""
                            )}>
                                <span className="text-3xl font-semibold">
                                    ₹ {vehicleData.outStationPrice * 24}
                                    <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">/ day</span>
                                </span>
                            </div>
                            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">Out Station</span>
                        </div>
                    }
                </div>

                {/* FORM */}
                <form className="flex border  border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                    <RentalCarDatesRangeInput
                        defaultDateValue={dateRangeValue}
                        defaultTimeValue={timeRangeValue}
                        numberOfMonths={2}
                        fieldClassName="p-3"
                        wrapFieldClassName="flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700"
                        className="RentalCarDetailPageDatesRangeInput flex-1"
                        onChange={(data) => {
                            setDateRangeValue(data.stateDate);
                            setTimeRangeValue(data.stateTimeRage);
                        }}
                        anchorDirection={'right'}
                        hasButtonSubmit={false}
                    />
                </form>
                {/* SUM */}
                <div className="flex flex-col space-y-4 ">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>₹ {(booking.outStation ? vehicleData.outStationPrice : vehicleData.price) * 24} x {Math.ceil(rentDuration / 24)} day(s)</span>
                        <span>₹ {rentPrice} /-</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Addons Charges </span>
                        <span>₹ {addonsPrice} /-</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Other Charges </span>
                        <span>₹ 0 /-</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>KM Limit</span>
                        <span>{Math.round(((booking.outStation ? vehicleData.KMLimitPerHourOutStation : vehicleData.KMLimitPerHour) || 5) * (Math.ceil(rentDuration / 24) * 24)).toFixed(0)} km(s)</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Extra Charge Per KM</span>
                        <span>₹ {vehicleData.extraKMCharges || 30} /-</span>
                    </div>

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>₹ {rentPrice + addonsPrice}</span>
                    </div>
                    <div className="flex justify-between font-semibold">
                        <span>Reservation Charges</span>
                        <span>&nbsp;₹ {
                            // reservation charges is 20% of total price 2 decimal places
                            Math.round((rentPrice + addonsPrice) * 0.2 * 100) / 100
                            // (((rentPrice + addonsPrice)/100)*20)
                        }</span>
                    </div>
                </div>
                <Modal className="dark:booking-confirmation" open={open} onCancel={() => setOpen(false)} footer={null} title={`Confirmation ${booking.outStation ? 'Out Station' : 'In Station'} booking`}>
                    <div className="flex flex-col text-neutral-900 dark:text-neutral-100">
                        {
                            // generate paragraph for user confirmation of outstation booking i.e. vehicle will be allowed to go out of city
                            booking.outStation ?
                                <p className="text-sm my-2">
                                    You are about to book a vehicle for <strong>outstation</strong> purpose. Please note that the vehicle will be allowed to go out of city.
                                </p>
                                :
                                <p className="text-sm my-2">
                                    You are about to book a vehicle for <strong>instation</strong> purpose. Please note that the vehicle will not be allowed to go out of city.
                                </p>

                        }
                        {
                            // if you wish to change the booking type, you can do so by clicking on the switch
                            <p className="text-sm my-2">
                                If you wish to change the booking type, you can do so by clicking on the switch.
                            </p>
                        }
                        {
                            // please note that you will be charged extra price per km of vehicleData.extraKMCharges after crossing the vehicleData.KMLimitPerHour * (Math.ceil(rentDuration / 24) *24)
                            vehicleData.extraKMCharges ?
                                <p className="text-sm mb-5">
                                    Please note that you will be charged <strong>extra price per km of ₹ {vehicleData.extraKMCharges || 5}</strong> after crossing the {((booking.outStation ? vehicleData.KMLimitPerHourOutStation : vehicleData.KMLimitPerHour) || 5) * (Math.ceil(rentDuration / 24) * 24)} km limit.
                                </p> : null

                        }
                        <div className="flex justify-between">
                            <span>₹ {(booking.outStation ? vehicleData.outStationPrice : vehicleData.price) * 24} x {Math.ceil(rentDuration / 24)} day(s)</span>
                            <span>₹ {rentPrice}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Addons & Other Charges </span>
                            <span>₹ {addonsPrice}</span>
                        </div>
                        <div className="border-b border-neutral-200 dark:border-neutral-700 my-2"></div>
                        <div className="flex justify-between font-semibold">
                            <span>Total </span>
                            <span>&nbsp;₹ {rentPrice + addonsPrice}</span>
                        </div>
                        <div className="flex justify-between font-semibold">
                            <span>Reservation Charges</span>
                            <span>&nbsp;₹ {
                                // reservation charges is 20% of total price 2 decimal places
                                Math.round((rentPrice + addonsPrice) * 0.2 * 100) / 100
                                // (((rentPrice + addonsPrice)/100)*20)
                            }</span>
                        </div>
                    </div>
                    <div className="flex flex-col mt-5">
                        <ButtonPrimary
                            sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                            onClick={() => {
                                const bookingData = {
                                    outStation: booking.outStation,
                                    vehicleData,
                                    rentDuration,
                                    rentPrice,
                                    addonsPrice,
                                    dateRangeValue,
                                    timeRangeValue,
                                };
                                setBooking(bookingData);
                                // if not logged in redirect to login page
                                if (!user.user) {
                                    history.push('/login');
                                    return;
                                }
                                history.push('/checkout');
                            }}
                        >
                            Book
                        </ButtonPrimary>
                    </div>

                </Modal>
                {/* SUBMIT */}
                <ButtonPrimary onClick={
                    () => {
                        // set booking data and redirect to /checkout
                        // const bookingData = {
                        //     outStation: booking.outStation,
                        //     vehicleData,
                        //     rentDuration,
                        //     rentPrice,
                        //     addonsPrice,
                        //     dateRangeValue,
                        //     timeRangeValue,
                        // };
                        // setBooking(bookingData);
                        // // if not logged in redirect to login page
                        // if (!user.user) {
                        //     history.push('/login');
                        //     return;
                        // }
                        // history.push('/checkout');
                        setOpen(true);
                    }
                }>Book</ButtonPrimary>
            </div>
        );
    };

    const renderSidebarDetail = () => {
        return (
            <div className="listingSection__wrap lg:shadow-xl">
                <span className="text-2xl font-semibold block">Pick up and drop off</span>
                <div className="mt-8 flex">
                    <div className="flex-shrink-0 flex flex-col items-center ">
                        <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                        <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                        <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                    </div>
                    <div className="ml-4 space-y-14 text-sm">
                        <div className="flex flex-col">
                            <span className="font-semibold">
                                {new Date(dateRangeValue.startDate).toLocaleString('en-in', {
                                    weekday: 'long', month: 'long', day: 'numeric'
                                })} · {timeRangeValue.startTime}

                            </span>
                            {/* <span className=" font-semibold">{vehicleData.location}</span> */}
                        </div>
                        <div className="flex flex-col space-y-2">
                            <span className="font-semibold">
                                {new Date(dateRangeValue.endDate).toLocaleString('en-in', {
                                    weekday: 'long', month: 'long', day: 'numeric'
                                })} · {timeRangeValue.endTime}
                            </span>
                            {/* <span className=" font-semibold">{vehicleData.location}</span> */}
                        </div>
                    </div>
                </div>
            </div >
        );
    };

    return (
        <div className={`ListingDetailPage mt-4 nc-ListingCarDetailPage ${className}`} data-nc-id="ListingCarDetailPage">
            {/* SINGLE HEADER */}
            {/* <div className='container flex flex-row justify-center items-center'> */}

            {/* MODAL PHOTOS */}

            {/* </div> */}

            {/* MAIn */}
            <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
                {/* CONTENT */}
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-4 lg:pr-10 lg:space-y-8">
                    <header className="h-auto sm:h-56 lg:h-64 2xl:px-0 rounded-md sm:rounded-xl">
                        <div className="relative h-full grid grid-cols-4 gap-1 sm:gap-2">
                            <div className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer" onClick={() => handleOpenModal(0)}>
                                <NcImage containerClassName="absolute inset-0" className="object-cover w-full h-full rounded-md sm:rounded-xl" src={vehicleData.images[0]} />
                                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                            </div>

                            {/*  */}
                            <div className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer" onClick={() => handleOpenModal(1)}>
                                <NcImage containerClassName="absolute inset-0" className="object-cover w-full h-full rounded-md sm:rounded-xl" src={vehicleData.images[1]} />
                                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                            </div>

                            {/*  */}
                            {vehicleData.images.filter((_: any, i: any) => i >= 2 && i < 4).map((item: any, index: any) => (
                                <div key={index} className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 2 ? 'block' : ''}`}>
                                    <NcImage containerClassName="aspect-w-4 aspect-h-5" className="object-cover w-full h-full rounded-md sm:rounded-xl " src={item || ''} />

                                    {/* OVERLAY */}
                                    <div
                                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                        onClick={() => handleOpenModal(index + 2)}
                                    />
                                </div>
                            ))}

                            <div
                                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                                onClick={() => handleOpenModal(0)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                    />
                                </svg>
                                <span className="ml-2 text-neutral-800 text-sm font-medium">Show all photos</span>
                            </div>
                        </div>
                    </header>
                    <ModalPhotos imgs={vehicleData.images} isOpen={isOpen} onClose={handleCloseModal} initFocus={openFocusIndex} uniqueClassName="nc-ListingCarDetailPage__modalPhotos" />
                    {
                        renderSection1_mod()
                    }
                    {renderSection1()}
                    {/* <div className="block lg:hidden">{renderSidebarDetail()}</div> */}
                    {renderSectionTienIch()}
                    {renderSection2()}
                    {renderSection3()}
                    {renderSectionCheckIndate()}
                    {/* {renderSection5()} */}
                    {/* {renderSection6()} */}
                    {/* {renderSection7()} */}
                    {renderSection8()}
                </div>

                {/* SIDEBAR */}
                <div className="block flex-grow mt-10 lg:mt-0">
                    {/* {renderSidebarDetail()} */}
                    <div className="hidden lg:block top-28">{renderSidebarPrice()}</div>
                </div>
            </main>

            {/* STICKY FOOTER MOBILE */}
            <MobileFooterSticky rentPrice={rentPrice} vehicleData={vehicleData} addonsPrice={addonsPrice} rentDuration={rentDuration} />

            {/* OTHER SECTION */}
            {/* <div className="container py-24 lg:py-32"> */}
            {/* SECTION 1 */}
            {/* <div className="relative py-16">
                    <BackgroundSection />
                    <SectionSliderNewCategories
                        heading="Explore by types of stays"
                        subHeading="Explore houses based on 10 types of stays"
                        categoryCardType="card5"
                        itemPerRow={5}
                        sliderStyle="style2"
                        uniqueClassName="ListingCarDetailPage"
                    />
                </div> */}

            {/* SECTION */}
            {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
            {/* </div> */}
        </div>
    );
};


const TimeSelect = ({ value, onChange }: any) => {
    const times = [
        // "12:00 AM",
        // "1:00 AM",
        // "2:00 AM",
        // "3:00 AM",
        // "4:00 AM",
        // "5:00 AM",
        // "6:00 AM",
        // "7:00 AM",
        "8:00 AM",
        "9:00 AM",
        "10:00 AM",
        "11:00 AM",
        "12:00 PM",
        "1:00 PM",
        "2:00 PM",
        "3:00 PM",
        "4:00 PM",
        "5:00 PM",
        "6:00 PM",
        "7:00 PM",
        "8:00 PM",
        // "9:00 PM",
        // "10:00 PM",
        // "11:00 PM",
    ];
    return (
        <div className='px-3 w-full'>

            <Listbox
                value={value}
                onChange={onChange}
                as="div"
                className="relative flex flex-row justify-between"
            >
                <Listbox.Button className="focus:outline-none inline-flex items-center group w-full flex-row justify-between">
                    <span className="text-base font-semibold">{value}</span>
                    <span className="ml-1 z-20 left-full top-0 text-neutral-400 group-hover:text-neutral-900 dark:group-hover:text-neutral-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                        </svg>
                    </span>
                </Listbox.Button>

                <Listbox.Options className="absolute z-50 min-w-max py-1 mt-5 overflow-auto text-base bg-white dark:bg-neutral-800 rounded-md shadow-lg max-h-60 ring-1 ring-black/5 dark:ring-white/20 focus:outline-none sm:text-sm">
                    {times.map((time, index) => (
                        <Listbox.Option
                            key={index}
                            className={({ active }) =>
                                `${active
                                    ? "text-amber-900 bg-amber-100"
                                    : "text-gray-900 dark:text-neutral-200"
                                } cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            value={time}
                        >
                            {({ selected, active }) => (
                                <>
                                    <span
                                        className={`${selected ? "font-medium" : "font-normal"
                                            } block truncate`}
                                    >
                                        {time}
                                    </span>
                                    {selected ? (
                                        <span
                                            className={`${active ? "text-amber-600" : "text-amber-600"
                                                }  absolute inset-y-0 left-0 flex items-center pl-3`}
                                        >
                                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    );
};

export default ListingCarDetailPage;
