import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import moment, { Moment } from "moment";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import { useBookingData } from "contexts/BookingContext";
import { useDateContext } from "contexts/DateContext";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import axios from "../../services/api";
import { UserContext } from "contexts/UserContext";
import ButtonSecondary from "shared/Button/ButtonSecondary";
export interface CheckOutPageProps {
  className?: string;
}
const getDate = (startDate: Moment, startTime: string) => {
  // if(startDate==null) return;

  var time = startTime.split(" ");
  var hour = time[0].split(":")[0];
  var min = time[0].split(":")[1];
  var ampm = time[1];
  if (ampm == "PM" && parseInt(hour) != 12) {
    hour = (parseInt(hour) + 12).toString();
  }
  var date = startDate?.toDate().setHours(parseInt(hour), parseInt(min), 0, 0);
  return date;
}
const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {

  const { dateRangeValue, setDateRangeValue }: any = useDateContext();
  const user: any = useContext(UserContext);
  const [mobileNo, setMobileNo] = useState<string>("");

  // if no user redirect to login page
  useEffect(() => {
    if (!user.user) {
      history.push("/login");
    }
  }, [user.user]);

  // useBookingData
  const { booking, setBooking } = useBookingData()
  const [loading, setLoading] = useState<boolean>(false);
  var history = useHistory();
  console.log(booking);

  // calculate reservation amount using all values from raw
  const calculateReservationAmount = () => {
    return Math.round((((booking.outStation ? booking.vehicleData.outStationPrice : booking.vehicleData.price) * 24) * Math.ceil(booking.rentDuration / 24) + (booking.addonsPrice || 0 + booking.vehicleData.otherPrice || 0)) * 0.2 * 100) / 100
  }

  // calculate total amount using all values from raw
  const calculateTotalAmount = () => {
    return Math.round((((booking.outStation ? booking.vehicleData.outStationPrice : booking.vehicleData.price) * 24) * Math.ceil(booking.rentDuration / 24) + (booking.addonsPrice || 0 + booking.vehicleData.otherPrice || 0)) * 100) / 100
  }

  const calculateKmLimit = () => {
    return (booking.outStation ? booking.vehicleData.KMLimitPerHourOutStation : booking.vehicleData.KMLimitPerHour || 5) * (Math.ceil(booking.rentDuration / 24) * 24)
  }

  const submitFn = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log('submit');

    if (!mobileNo || mobileNo.length < 10) {
      message.error("Please enter valid mobile number");
      setLoading(false);
      return;
    }

    const start = getDate(booking.dateRangeValue.startDate, booking.timeRangeValue?.startTime)
    const end = getDate(booking.dateRangeValue.endDate, booking.timeRangeValue.endTime)

    console.log(start, end);

    // get firebase token
    const token = await user.user?.getIdToken(true);

    console.log(booking.outStation);

    axios.post("/bookVehicle", {
      // "startDate": booking.,
      "startDate": start,
      "endDate": end,
      "vehicleId": booking.vehicleData.id,
      "actualPrice": calculateTotalAmount(),
      "amount": calculateReservationAmount(),
      "reserveAmount": calculateReservationAmount(),
      "discount": 0,
      "outStation": booking.outStation,
      "actualPickupDate": new Date(),
      "calculatedKmLimit": calculateKmLimit(),
      "calculatedHours": booking.rentDuration,
      "addons": [],
      "addonsAmount": 0,
      "status": "pending",
      "mobile": mobileNo,
      "payments": [
        {
          "paymentType": "reservation",
          "amount": calculateReservationAmount(),
          "status": "pending"
        },
        {
          "paymentType": "balance",
          "amount": calculateTotalAmount() - calculateReservationAmount(),
          "status": "pending"
        }
      ]
    }, {
      headers: {
        Authorization: `${token}`
      }
    }).then((res) => {

      console.log(res.data);
      if (!res.data.paymentUrl) {
        throw new Error('Payment url not found')
      }
      var paymentData = res.data.data

      // create form
      var form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', res.data.paymentUrl);
      form.setAttribute('style', 'display:none');

      // create input for each key in paymentData
      for (var key in paymentData) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', key);
        input.setAttribute('value', paymentData[key]);
        form.appendChild(input);
      }
      // submit form
      document.body.appendChild(form);
      form.submit();



    }).catch((err) => {
      message.error({ content: err?.response?.data || "Failed booking vehicle please ensure vehicle is available", key: 'checkout' })
      setLoading(false);
      console.log(err);
    })

  }
  if (Object.keys(booking).length <= 1) {
    history.push('/')
    message.error(
      {
        content: 'Please select a vehicle first',
        key: 'checkout'
      }
    )
  }

  console.log(booking);

  // const [guests, setGuests] = useState<GuestsObject>({
  //   guestAdults: 2,
  //   guestChildren: 1,
  //   guestInfants: 1,
  // });

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={booking?.vehicleData?.images[0]} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {
                  booking.vehicleData.vehicleName
                }
              </span>
              <span className="text-base font-medium mt-1 block capitalize">
                {
                  booking.vehicleData.type
                }
              </span>
            </div>
            {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              2 beds · 2 baths
            </span> */}
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>₹ {(booking.outStation ? booking.vehicleData.outStationPrice : booking.vehicleData.price) * 24} x {Math.ceil(booking.rentDuration / 24)} day(s)</span>
            <span>₹ {booking.rentPrice} /-</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>KM Limit</span>
            <span>{calculateKmLimit()} km(s)</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Extra Charge Per KM</span>
            <span>₹ {booking.vehicleData.extraKMCharges || 30} /-</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Addon charge</span>
            <span>
              {
                `₹ ${booking?.addonsPrice ? booking?.addonsPrice : 0}`
              }
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>
              {
                `₹ ${booking?.vehicleData?.otherPrice ? booking?.vehicleData?.otherPrice : 0}`
              }
            </span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>

              {
                `₹ ${calculateTotalAmount()}`
              }
            </span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>Reservation Charges</span>
            <span>&nbsp;₹ {
              // reservation charges is 20% of total price 2 decimal places
              calculateReservationAmount()
              // (((rentPrice + addonsPrice)/100)*20)
            }</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Payment Due on Ride</span>
            <span>&nbsp;₹ {
              (booking.rentPrice + booking.addonsPrice)
              - (Math.round((booking.rentPrice + booking.addonsPrice) * 0.2 * 100) / 100)
              // (((rentPrice + addonsPrice)/100)*20)
            }</span>
          </div>

        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">You're booking {booking.vehicleData.vehicleName} for {
              booking.outStation ? 'outstation' : 'instation'
            } purpose
            </h3>
            <div className="flex flex-col sm:flex-row sm:items-center my-2">
              We are currently only accepting reservation fees which is non-refundable.
              The remaining balance must be paid upon pickup. Please note that additional charges may apply if you exceed the kilometer limit which will be calculated upon return.
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center my-2">
              Also note that you need to carry your driving license and aadhar card for verification.
            </div>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            {/* <ModalSelectDate
              defaultValue={rangeDates}
              onSelectDate={setRangeDates}
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 "
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString(rangeDates)}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            /> */}
            <div className="p-4">
              {
                // JSON.stringify(booking.dateRangeValue)
                // booking.dateRangeValue.startDate format MMM DD
                booking?.dateRangeValue?.startDate.format('MMM DD') + ' ' + booking?.timeRangeValue?.startTime
                + ' - ' + booking?.dateRangeValue?.endDate.format('MMM DD') + ' ' + booking?.timeRangeValue?.endTime

              }

            </div>
            {/* <ModalSelectGuests
              defaultValue={guests}
              onChangeGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${(guests.guestAdults || 0) +
                          (guests.guestChildren || 0)
                          } Guests, ${guests.guestInfants || 0} Infants`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            /> */}
          </div>
        </div>

        <div>
          {/* <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div> */}

          <div className="mt-0">
            {/* <Tab.Group>
              <Tab.List className="flex my-5">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                        : "text-neutral-6000 dark:text-neutral-400"
                        }`}
                    >
                      PayU
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${selected
                        ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                        : " text-neutral-6000 dark:text-neutral-400"
                        }`}
                    >
                      <span className="mr-2.5">Credit card</span>
                      <img className="w-8" src={visaPng} alt="" />
                      <img className="w-8" src={mastercardPng} alt="" />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input defaultValue="111 112 222 999" />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input defaultValue="JOHN DOE" />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group> */}
            <div className="pt-0">
              <form onSubmit={submitFn}>
                <div className="space-y-1">
                  <Label>Moblie Number</Label>
                  <Input
                    type="text"
                    placeholder="Mobile Number"
                    value={mobileNo}
                    required
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                </div>
                <ButtonPrimary className="mt-4" loading={loading} type="submit">Confirm and pay ₹ {
                  // reservation charges is 20% of total price 2 decimal places
                  Math.round((booking.rentPrice + booking.addonsPrice) * 0.2 * 100) / 100
                  // (((rentPrice + addonsPrice)/100)*20)
                }</ButtonPrimary>
                {
                  // back button
                  <ButtonSecondary className="mt-4 ml-4" type="button"  onClick={() => history.goBack()}>Back</ButtonSecondary>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
